  <template>
  <v-row class="ml-2 mr-2 mb-0">
    <number-field
      :disabled="!promise.canUpdate"
      :rules="toleranceDaysRules"
      :number.sync="toleranceDays"
      :label="$t('t.ToleranceDays')"
      class="ml-2 mr-5"
    />
    <amount-field
      v-if="!isToleranceAmountPercentage"
      :disabled="!promise.canUpdate"
      :rules="toleranceAmountRules"
      :amount.sync="toleranceAmount"
      :label="$t('t.ToleranceAmount')"
      :currency.sync="this.promise.currency"
      reverse
    />
    <number-field
      v-else
      :disabled="!promise.canUpdate"
      :rules="toleranceAmountRules"
      :number.sync="toleranceAmount"
      :label="$t('t.ToleranceAmount')"
    />
    <v-radio-group
      :disabled="!promise.canUpdate"
      v-model="isToleranceAmountPercentage"
      row
      class="ml-2"
    >
      <v-radio
        :label="currencySymbol(promise.currency)"
        :value="false"
      ></v-radio>
      <v-radio
        label="%"
        :value="true"
      ></v-radio>
    </v-radio-group>
  </v-row>
</template>

<script>
export default {
  components: {
    AmountField: () => import('@/components/amount-field'),
    NumberField: () => import('@/components/number-field')
  },
  computed: {
    isToleranceAmountPercentage: {
      get: function () {
        return this.promise.isToleranceAmountPercentage
      },
      set: function (isToleranceAmountPercentage) {
        this.$emit('update:promise', Object.assign({}, this.promise, { isToleranceAmountPercentage }))
      }
    },
    lowestDaysBetweenInstallments () {
      let days
      this.promise.installments.forEach((i, index) => {
        if (index) {
          const daysAfterPreviousInstallment = this.moment(i.targetDate).diff(this.moment(this.promise.installments[index - 1].targetDate, 'YYYY-MM-DD'), 'days')
          if (daysAfterPreviousInstallment < days) {
            days = daysAfterPreviousInstallment
          }
        } else {
          days = 100
        }
      })
      return days
    },
    toleranceAmount: {
      get: function () {
        return this.promise.toleranceAmount
      },
      set: function (toleranceAmount) {
        this.$emit('update:promise', Object.assign({}, this.promise, { toleranceAmount }))
      }
    },
    toleranceAmountRules () {
      return [
        v =>
          (Number.isFinite(v) && v >= 0 &&
            (this.isToleranceAmountPercentage
              ? (v < 100)
              : (v < Math.min(...this.promise.installments.map(i => i.amount ?? Infinity)))
            )
          ) || this.$t('t.InvalidTolerance')
      ]
    },
    toleranceDays: {
      get: function () {
        return this.promise.toleranceDays
      },
      set: function (toleranceDays) {
        this.$emit('update:promise', Object.assign({}, this.promise, { toleranceDays }))
      }
    },
    toleranceDaysRules () {
      return [
        v => (v >= 0 && v < this.lowestDaysBetweenInstallments) || this.$t('t.InvalidValueRange', { min: 0, max: this.lowestDaysBetweenInstallments - 1 })
      ]
    }
  },
  props: {
    promise: Object
  }
}
</script>

<style lang="stylus" scoped></style>
